import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';

export class UsersSelectors {
  public static readonly selectUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);

  public static readonly selectUserLoading = createSelector(
    UsersSelectors.selectUsersState,
    (state) => state.loading,
  );

  public static readonly selectUserLoaded = createSelector(
    UsersSelectors.selectUsersState,
    (state) => state.loaded,
  );

  public static readonly selectUserError = createSelector(
    UsersSelectors.selectUsersState,
    //eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (state) => state.error,
  );

  public static readonly activeUser = createSelector(
    UsersSelectors.selectUsersState,
    (state: UsersState) => state.user,
  );

  public static readonly activeUserPersonContract = createSelector(
    UsersSelectors.activeUser,
    (user) => {
      if (!user) {
        return undefined;
      }

      return {
        '@id': `/api/people/${user.id}`,
        '@type': 'Person',
        'fullName': user.fullName,
      };
    },
  );

  public static readonly activeUsersLocationMembers = createSelector(
    UsersSelectors.selectUsersState,
    (state: UsersState) => state.currentLocationMembers,
  );

  public static readonly roles = createSelector(
    UsersSelectors.activeUser,
    (user) => user?.roles ?? [],
  );
}
